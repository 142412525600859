<template>
    <div class="background-1 page-height">
      <v-container class="landing-header-container">
          <div class="row align-center pt-2">
              <div class="col col-xs-12 col-md-12 pb-0 pt-6 my-cars-title">
                  <span class="landing-title-1">Welcome to</span>
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-md-12 pb-0 pt-0 my-cars-title">
                  <span class="landing-title-2">OPen Road Auto Storage</span>
              </div>
          </div>
      </v-container>

      <v-container class="landing-body-container">
        <div class="landing-body">
          <div class="row">
            <div class="col-12 col-md-5 col-lg-4 col-xs-12 pt-0 pb-0">
              <img class="landing-image" src="../assets/img/landing_page.png" />
            </div>
            <div class="col-12 col-md-7 col-lg-8 col-xs-12 pt-4 pb-4">
              <div class="landing-page-content">
                <span class="font-size-16">
                  A dedicated storage equipped with state-of-the-art facilities such as an air-conditioned and humidity-controlled environment, 
                  regulated electricity supply for hybrid and electric vehicles, to 24/7 manned security services and first-class security systems, 
                  OPen Road Auto Storage ensures a peace of mind for all car owners. 
                </span>
                <br><br>
                <span class="font-size-16">
                  OPen Road Auto Storage is also the <u>FIRST AND ONLY ONE</u> to be licensed by Majlis Perbandaran Langkawi to 
                  conduct a licensed auto storage business.
                </span>
              </div>
            </div>
          </div>
        </div>
          <div v-if="!currentUser">
            <div class="row pt-6">
              <div class="col">
                <span class="font-size-18">For our customer, please login here</span>
              </div>
            </div>
            <div class="row">
              <div class="col pt-0">
                <v-btn
                  rounded
                  class="login-button landing-login-button"
                  @click="goLogin"
                ><span>Login</span></v-btn>
              </div>
            </div>
          </div> 
      </v-container>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version } from "/src/constants/config";
import { mapActions } from "vuex";
export default {
  mixins: [currentUserMixin],
  data() {
        return {          
        }
  },
    
  components: {
  },

  mounted() {
    if(this.currentUser) //for checking user token still valid or not
    {
      this.getCarItems();
    }    
  },

  methods: {
    goLogin(){
      this.$router.push("/login");
    },

    ...mapActions(["signOut"]),
    getCarItems(){
        let url = openRoadApiUrl + "/api/services/" + version + "/car/me/list";
        axios.get(url, this.$data.apiHeaders)
            .then(response => this.setCarItems(response))
            .catch(error => this.onError(error));
    },

    setCarItems(response) {          
    },

    onError(error){
        let message = "Failed";
        let details = error && error.response && error.response.data.error 
                ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                : "";
        if(error.response.status == 401) this.logout(message, details);
        else {
          this.$notify("error", message, details, {
            duration: 6000,
            permanent: false
          });
        }
    },

    logout(message, details) {
      this.signOut();
    },        
  },
  
  computed: {
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
