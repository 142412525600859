<template>
    <div class="d-flex flex-column justify-center page-height background-1">
        <v-container class="authentication-container" >
        <v-row>
          <v-col xs="12" class="pb-0"> 
            <span class="app-title-2 line-height-32">Welcome to</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" class="pt-0 pb-0">
            <span class="app-title">OPen Road Auto Storage</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" class="pt-6 pb-0">
            <span class="color-theme-3"><b>Please login here</b></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" lg="3" sm="6" class="pt-0 pb-0 text-input">
            <v-text-field
              v-model="form.email"
              label="Username"
              :rules="rules"
              hide-details="auto"
              background-color="white"
              color="gray"
              @keydown.enter="formSubmit"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row >
          <v-col xs="12" lg="3" sm="6" class="pt-0 pb-0 text-input">
            <v-text-field
            v-model="form.password"
            label="Password"            
            :rules="[passwordrules.required]"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            value="wqfasds"
            class="password"
            background-color="white"
            @click:append="show = !show"
            @keydown.enter="formSubmit"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col xs="12" class="pt-0 pb-0">
            <router-link to="/forgotpassword">
              <span class="color-theme-2 text-underline">Forget Password? </span>
            </router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12">            
            <v-btn
              rounded
              class="login-button"
              @click="formSubmit"
            >
              <span v-if="!isProcessing">Login</span>
              <div class="login-popup-visible">
                <v-progress-linear v-if="isProcessing" buffer-value="0" size="100" stream color="white" class="action-photo-loader"></v-progress-linear>
              </div>
            </v-btn>
          </v-col>
        </v-row>       
        <v-row>
          <v-col xs="12" class="pt-0">
            <span class="color-theme-2">Not a member yet? </span>
            <a href="tel:+6016-316 7386" class="call-us">Call us now </a>
          </v-col>
        </v-row>
      </v-container>
      </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
        return {
          isProcessing: false,
            form: {
                email: "", 
                password: "",
            },
            show: false,

            rules: [
              value => !!value || 'Required.',
            ],
            passwordrules: {
              required: value => !!value || 'Required.',
            },
        };
  },
    
  components: {
  },

  mounted() {
    this.proceedNextPath();
  },

  methods: {
        ...mapActions(["login"]),
        formSubmit() {   
          this.isProcessing = true;    
          this.login({
              email: this.form.email,
              password: this.form.password
          });    
        },

          proceedNextPath(){
          if(this.currentUser && !this.currentUser.isStaff){
            this.$router.push("/mycars");
          } else if(this.currentUser && this.currentUser.isStaff){
            this.$router.push("/staff/all-tasks");
          }
        },
    },

  
    computed: {
        ...mapGetters(["currentUser", "processing", "loginError"])
    },
    watch: {
        currentUser(val) {
            this.isProcessing = false;
            if (val && val.uid) {             
                setTimeout(() => {
                    if(this.currentUser.isStaff){
                      this.$router.push("/staff/all-tasks");
                    }
                    else{
                      this.$router.push("/mycars");
                    }                   
                }, 1000);
            }
        },
        loginError(val) {
          this.isProcessing = false;
          if (val != null) {            
              this.$notify("error", val.message, val.details, {
                  duration: 3000,
                  permanent: false
              });
          }          
        }, 
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
